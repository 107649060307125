import React, { useContext, useEffect, useState } from "react";
// import { Navigate, Route, Router } from "react-router-dom";
// import useRequest from "../../hooks/use-request";
// import AuthContext from "../../store/auth-context";
import Layout from "../../components/Layout/Layout";
import Profile from "../Profile/Profile";
import User from "../Users/User";
import { Navigate, Route, Routes } from "react-router-dom";
import AuthContext from "../../store/auth-context";
import useRequest from "../../hooks/use-request";
import Loading from "../../components/Loading/Loading";
import News from "../News/News";
import FeedbackStatus from "../FeedbackStatus/FeedbackStatus";
import Chat from "../Chat/Chat";
import CancelingReasons from "../CancelingReasons/CancelingReasons";
import Discount from "../Discounts/Discount";
import ExtraNews from "../ExtraNews/ExtraNews";

const Main = () => {
  const [role, setRole] = useState(null);
  const authCtx = useContext(AuthContext);

  const { loading, doRequest, errors } = useRequest({
    url: `/auth/user`,
    method: "get",
    headers: {
      Authorization: `Bearer ${authCtx.token}`,
    },
    onSuccess: (data) => {
      setRole(data.data.role);
    },
  });

  useEffect(() => {
    doRequest();
  }, []);
  return (
    <React.Fragment>
      {loading && <Loading />}
      <Layout role={role}>
        <Routes>
          <Route path="/" element={<Chat />} />
          {role === "admin" && <Route path="/users" element={<User />} />}
          {role === "admin" && <Route path="/news" element={<News />} />}
          {role === "admin" && <Route path="/extra-news" element={<ExtraNews />} />}
          {role === "admin" && (
            <Route path="/feedback-status" element={<FeedbackStatus />} />
          )}
          <Route path="/canseling-reasons" element={<CancelingReasons />} />
          {role === "admin" && (
            <Route path="/discount" element={<Discount />} />
          )}
          <Route path="/profile" element={<Profile />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Layout>
    </React.Fragment>
  );
};

export default Main;
