import React, { useContext, useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import {
  FormControl,
  MenuItem,
  Select,
  TableCell,
  TableRow,
} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Table from "../../components/Table/Table";
import AuthContext from "../../store/auth-context";
import useRequest from "../../hooks/use-request";
import Loading from "../../components/Loading/Loading";

const News = () => {
  const [createUser, setCreateUser] = useState(false);
  const [updateUser, setUpdateUser] = useState(false);
  const [title_uz, setTitleUz] = useState("");
  const [title_ru, setTitleRu] = useState("");
    const [title_en, setTitleEn] = useState("");
    const [title_kr, setTitleKr] = useState("");
const [title_gr, setTitleGr] = useState("");
const [title_krKrill, setTitleKrKrill] = useState("");
const [title_uzKrill, setTitleUzKrill] = useState("");
const [description_uz, setDescriptionUz] = useState("");
const [description_ru, setDescriptionRu] = useState("");
const [description_en, setDescriptionEn] = useState("");
const [description_kr, setDescriptionKr] = useState("");
const [description_gr, setDescriptionGr] = useState("");
const [description_krKrill, setDescriptionKrKrill] = useState("");
const [description_uzKrill, setDescriptionUzKrill] = useState("");
  const [link, setLink] = useState("");
  const [image, setImage] = useState("");
  const [confirmedValue, setConfirmedValue] = useState("tashkent");
  const [news, setNews] = useState([]);
  const [zone, setZone] = useState("tashkent");

  const [onSuccessMsg, setOnSuccessMsg] = useState(null);
  const authCtx = useContext(AuthContext);

  const newsData = new FormData();

  const handleChangeSorting = (event) => {
    setConfirmedValue(event.target.value);
  };

  const handleChangeZone = (event) => {
    setZone(event.target.value);
  };

  useEffect(() => {
    getNews.doRequest();
  }, [confirmedValue]);
  const getNews = useRequest({
    url2: `https://feed.geogo.io/api/v1/news/${confirmedValue}?lan=all`,
    method: "get",
    headers: {
      Authorization: `Bearer ${authCtx.token}`,
    },
    onSuccess: (data) => {
      setNews(data.data);
    },
  });

  const addNews = useRequest({
    url2: `https://feed.geogo.io/api/v1/news`,
    method: "post",
    headers: {
      Authorization: `Bearer ${authCtx.token}`,
    },
    body: newsData,
    onSuccess: (data) => {
      getNews.doRequest();
      setCreateUser(false);
      setOnSuccessMsg("ExtraNews yaratildi.");
    },
  });

  const deleteNewsRequest = useRequest({
    url2: `https://feed.geogo.io/api/v1/news`,
    method: "delete",
    headers: {
      Authorization: `Bearer ${authCtx.token}`,
    },
    body: newsData,
    onSuccess: (data) => {
      getNews.doRequest();
      setCreateUser(false);
      setOnSuccessMsg("ExtraNews o'chirildi.");
    },
  });

  const deleteNews = async (id) => {
    if (window.confirm("Are you sure?")) {
      await deleteNewsRequest.doRequest(id);
      setOnSuccessMsg(null);
    }
  };

  const onSubmitNewsData = async (e) => {
    e.preventDefault();
    newsData.append("title_uz", title_uz);
    newsData.append("title_ru", title_ru);
    newsData.append("title_en", title_en);
    newsData.append("title_kr", title_kr);
    newsData.append("title_gr", title_gr);
    newsData.append("title_krKrill", title_krKrill);
    newsData.append("title_uzKrill", title_uzKrill);
    newsData.append("description_uz", description_uz);
    newsData.append("description_ru", description_ru);
    newsData.append("description_en", description_en);
    newsData.append("description_kr", description_kr);
    newsData.append("description_gr", description_gr);
    newsData.append("description_krKrill", description_krKrill);
    newsData.append("description_uzKrill", description_uzKrill);
    newsData.append("link", link);
    newsData.append("zone", zone);
    newsData.append("image", image);

    await addNews.doRequest();
  };

  return (
    <React.Fragment>
      {getNews.loading ? <Loading /> : null}
      {addNews.loading ? <Loading /> : null}
      {deleteNews.loading ? <Loading /> : null}
      <div className="profile-container">
        <div className="right-section">
          <div className="right-section-header">
            <div
              className={
                createUser
                  ? "right-section-control"
                  : "right-section-control active"
              }
            >
              <button
                onClick={() => {
                  setCreateUser(false);
                  setUpdateUser(false);
                  //   getUser.doRequest();
                }}
              >
                News List
              </button>
            </div>
            <div
              className={
                !createUser
                  ? "right-section-control"
                  : "right-section-control active"
              }
            >
              <button onClick={() => setCreateUser(true)}>{"Add ExtraNews"}</button>
            </div>
          </div>
          {!createUser ? (
            <>
              <div className="sorting-container" style={{ marginTop: "20px" }}>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <Select
                    value={confirmedValue}
                    onChange={handleChangeSorting}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value={"tashkent"}>Tashkent</MenuItem>
                    <MenuItem value={"geogievsk"}>Geogievsk</MenuItem>
                    <MenuItem value={"gelendjik"}>Gelendjik</MenuItem>
                    <MenuItem value={"yangiyul"}>Yangiyul</MenuItem>
                    <MenuItem value={"navoiy"}>Navoiy</MenuItem>
                    <MenuItem value={"jizzax"}>Jizzax</MenuItem>
                    <MenuItem value={"nukus"}>Nukus</MenuItem>
                    <MenuItem value={"urgench"}>Urgench</MenuItem>
                    <MenuItem value={"buxoro"}>Buxoro</MenuItem>
                    <MenuItem value={"samarqand"}>Samarqand</MenuItem>
                    <MenuItem value={"guliston"}>Guliston</MenuItem>
                    <MenuItem value={"qarshi"}>Qarshi</MenuItem>
                    <MenuItem value={"namangan"}>Namangan</MenuItem>
                    <MenuItem value={"denov"}>Denov</MenuItem>
                    <MenuItem value={"korea"}>Korea</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <Table
                headers={[
                  "title Uz",
                    "title Ru",
                    "title En",
                    "title Kr",
                    "title Gr",
                    "title KrKrill",
                    "title UzKrill",
                    "description Uz",
                    "description Ru",
                    "description En",
                    "description Kr",
                    "description Gr",
                    "description KrKrill",
                    "description UzKrill",
                  "link",
                  "date",
                  "image",
                  "Delete",
                ]}
              >
                {news.map((n) => (
                  <TableRow
                    key={n.id}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {n.title_uz}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {n.title_ru}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {n.title_en}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {n.title_kr}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {n.title_gr}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {n.title_krKrill}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {n.title_uzKrill}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {n.description_uz}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {n.description_ru}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {n.description_en}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {n.description_kr}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {n.description_gr}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {n.description_krKrill}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {n.description_uzKrill}
                    </TableCell>
                    <TableCell align="left">{n.link}</TableCell>
                    <TableCell align="left">{n.date}</TableCell>
                    <TableCell align="left">
                      <img
                        crossorigin="anonymous"
                        src={`https://feed.geogo.io/${n.image}`}
                        style={{ width: "100px" }}
                      />
                    </TableCell>
                    <TableCell align="left" style={{ color: "red" }}>
                      <DeleteOutlineOutlinedIcon
                        onClick={() => deleteNews(n.id)}
                        style={{ cursor: "pointer" }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </Table>
            </>
          ) : (
            <div className="right-section-details">
              <form onSubmit={onSubmitNewsData}>
                <div className="detail">
                  <label>Title Uz</label>
                  <input
                    type={"text"}
                    value={title_uz}
                    onChange={(e) => setTitleUz(e.target.value)}
                    required={true}
                  />
                </div>
                <div className="detail">
                  <label>Title Ru</label>
                  <input
                      type={"text"}
                      value={title_ru}
                      onChange={(e) => setTitleRu(e.target.value)}
                      required={true}
                  />
                </div>
                <div className="detail">
                  <label>Title En</label>
                  <input
                      type={"text"}
                      value={title_en}
                      onChange={(e) => setTitleEn(e.target.value)}
                      required={true}
                  />
                </div>
                <div className="detail">
                  <label>Title Kr</label>
                  <input
                      type={"text"}
                      value={title_kr}
                      onChange={(e) => setTitleKr(e.target.value)}
                      required={true}
                  />
                </div>
                <div className="detail">
                  <label>Title Gr</label>
                  <input
                      type={"text"}
                      value={title_gr}
                      onChange={(e) => setTitleGr(e.target.value)}
                      required={true}
                  />
                </div>
                <div className="detail">
                  <label>Title UzKrill</label>
                  <input
                      type={"text"}
                      value={title_uzKrill}
                      onChange={(e) => setTitleUzKrill(e.target.value)}
                      required={true}
                  />
                </div>
                <div className="detail">
                  <label>Title KrKrill</label>
                  <input
                      type={"text"}
                      value={title_krKrill}
                      onChange={(e) => setTitleKrKrill(e.target.value)}
                      required={true}
                  />
                </div>
                <div className="detail">
                  <label>Description Uz</label>
                  <textarea
                    type={"text"}
                    value={description_uz}
                    onChange={(e) => setDescriptionUz(e.target.value)}
                    required={true}
                    style={{ height: "100px" }}
                  ></textarea>
                </div>
                <div className="detail">
                  <label>Description Ru</label>
                  <textarea
                      type={"text"}
                      value={description_ru}
                      onChange={(e) => setDescriptionRu(e.target.value)}
                      required={true}
                      style={{ height: "100px" }}
                  ></textarea>
                </div>
                <div className="detail">
                  <label>Description En</label>
                  <textarea
                      type={"text"}
                      value={description_en}
                      onChange={(e) => setDescriptionEn(e.target.value)}
                      required={true}
                      style={{ height: "100px" }}
                  ></textarea>
                </div>
                <div className="detail">
                  <label>Description Kr</label>
                  <textarea
                      type={"text"}
                      value={description_kr}
                      onChange={(e) => setDescriptionKr(e.target.value)}
                      required={true}
                      style={{ height: "100px" }}
                  ></textarea>
                </div>
                <div className="detail">
                  <label>Description Gr</label>
                  <textarea
                      type={"text"}
                      value={description_gr}
                      onChange={(e) => setDescriptionGr(e.target.value)}
                      required={true}
                      style={{ height: "100px" }}
                  ></textarea>
                </div>
                <div className="detail">
                  <label>Description UzKrill</label>
                  <textarea
                      type={"text"}
                      value={description_uzKrill}
                      onChange={(e) => setDescriptionUzKrill(e.target.value)}
                      required={true}
                      style={{ height: "100px" }}
                  ></textarea>
                </div>
                <div className="detail">
                  <label>Description KrKrill</label>
                  <textarea
                      type={"text"}
                      value={description_krKrill}
                      onChange={(e) => setDescriptionKrKrill(e.target.value)}
                      required={true}
                      style={{ height: "100px" }}
                  ></textarea>
                </div>
                <div className="detail">
                  <label>Link</label>
                  <input
                    type={"text"}
                    value={link}
                    onChange={(e) => setLink(e.target.value)}
                  />
                </div>
                <div className="detail">
                  <label>Image</label>
                  <input
                    type={"file"}
                    // value={}
                    onChange={(e) => setImage(e.target.files[0])}
                  />
                </div>
                <div className="detail">
                  <label>Zone</label>
                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <Select
                      value={zone}
                      onChange={handleChangeZone}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value={"tashkent"}>Tashkent</MenuItem>
                      <MenuItem value={"geogievsk"}>Geogievsk</MenuItem>
                      <MenuItem value={"gelendjik"}>Gelendjik</MenuItem>
                      <MenuItem value={"yangiyul"}>Yangiyul</MenuItem>
                      <MenuItem value={"navoiy"}>Navoiy</MenuItem>
                      <MenuItem value={"jizzax"}>Jizzax</MenuItem>
                      <MenuItem value={"nukus"}>Nukus</MenuItem>
                      <MenuItem value={"urgench"}>Urgench</MenuItem>
                      <MenuItem value={"buxoro"}>Buxoro</MenuItem>
                      <MenuItem value={"samarqand"}>Samarqand</MenuItem>
                      <MenuItem value={"guliston"}>Guliston</MenuItem>
                      <MenuItem value={"qarshi"}>Qarshi</MenuItem>
                      <MenuItem value={"namangan"}>Namangan</MenuItem>
                      <MenuItem value={"denov"}>Denov</MenuItem>
                      <MenuItem value={"korea"}>Korea</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div style={{ margin: "20px 0" }}>
                  <LoadingButton
                    className="btn"
                    loading={addNews.loading}
                    type="submit"
                    variant="contained"
                  >
                    {updateUser ? "Save Changes" : "Create ExtraNews"}
                  </LoadingButton>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default News;
